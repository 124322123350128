@import "../base/vars";
@import "../base/mixins";
@import "../components/cards";

.index--intro {
	@include background("/static/build/img/main_page/map.svg", $position: calc(50% + 400px) 0, $color: $background);
	padding-bottom: 0;

	@include max-width($tablet) {
		background-size: 620px;
		background-position: 50% 200px;
	}

	.wrapper {
		padding-bottom: 100px;
		min-height: 600px;
		
		@include max-width($tablet) {
			padding-bottom: 20px;
		}
	}

	.intro_content {
		position: relative;
		max-width: 65%;
		z-index: 1;

		@include max-width($tablet) {
			max-width: initial;
		}
	}

	.h1 {
		margin: 24px 0;

		@include max-width($tablet) {
			margin: 12px 0;
		}
	}

	.catalogs {
		display: flex;
		flex-wrap: wrap;

		@include max-width($tablet) {
			flex-wrap: nowrap;
			flex-direction: column;
			align-items: center;
			margin-top: 310px;
		}
	}

	.catalogs.limited .catalog:nth-child(n + 4) {
		@include max-width($tablet) {
			display: none;
		}
	}

	.catalog {
		margin-bottom: 12px;
		margin-right: 12px;

		@include max-width($tablet) {
			width: 100%;
			margin-right: 0;
		}
	}

	.gs_label {
		@include fluid-text($phone, $desktop_large, 12px, 16px, 16px, 26px);
	}

	.picture--1 {
		position: absolute;
		top: 50%;
		right: 16px;
		transform: translateY(-48%);

		@include max-width($tablet) {
			top: 136px;
			right: 0;
			transform: none;
		}
	}

	.picture--1 img {
		width: 388px;

		@include max-width($desktop_large) {
			width: 325px;
		}

		@include max-width($tablet_wide) {
			width: 250px;
		}

		@include max-width($tablet) {
			width: auto;
			height: 200px;
		}
	}

	.picture--2 {
		position: absolute;
		top: 12px;
		right: 440px;

		@include max-width($desktop_large) {
			right: 340px;
		}

		@include max-width($tablet_wide) {
			right: 200px;
		}

		@include max-width($tablet) {
			top: 240px;
			right: auto;
			left: 0;
		}
	}

	.picture--2 img {
		width: 261px;

		@include max-width($desktop_large) {
			width: 200px;
		}

		@include max-width($tablet_wide) {
			width: 150px;
		}

		@include max-width($tablet) {
			width: auto;
			height: 143px;
		}
	}

	.picture--3 {
		position: absolute;
		bottom: 0;
		right: 395px;

		@include max-width($desktop_large) {
			right: 300px;
		}

		@include max-width($tablet_wide) {
			right: 250px;
		}

		@include max-width($tablet) {
			bottom: auto;
			top: 330px;
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.picture--3 img {
		width: 208px;

		@include max-width($tablet_wide) {
			width: 150px;
		}

		@include max-width($tablet) {
			width: auto;
			height: 112px;
		}
	}
}

.index--promotion {

	.promotion_card {
		@extend .gs_card;
		background: linear-gradient(0deg, rgba(6, 6, 37, 0.5), rgba(6, 6, 37, 0.5)), #FFFFFF;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 38px;

		@include max-width($tablet_wide) {
			padding: 24px;
		}
	}
}

.index--grand_class {

	.grand_class_card {
		@extend .gs_card;
		@include background("/static/build/img/main_page/grand_class.jpg", $size: cover);
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include max-width($tablet) {
			background-image: url("/static/build/img/main_page/grand_class--mobile.jpg");
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.grand_class_card > * + * {
		margin-left: 24px;

		@include max-width($tablet) {
			margin-left: 0;
			margin-top: 24px;
		}
	}
}
