@mixin max-width($width) {
	@media screen and (max-width: #{$width}) {
		@content
	}
};
  
@mixin min-width($width) {
	@media screen and (min-width: #{$width}) {
		@content
	}
};
  
@mixin fluid-text($min_vw, $max_vw, $min_font_size, $max_font_size, $min_line_height, $max_line_height) {
	$u1: unit($min_vw);
	$u2: unit($max_vw);
	$u3: unit($min_font_size);
	$u4: unit($max_font_size);
	$u5: unit($min_line_height);
	$u6: unit($max_line_height);
  
	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min_font_size;
			line-height: $min_line_height;
			@media screen and (min-width: $min_vw) {
				font-size: calc(#{$min_font_size} + #{strip-unit($max_font_size - $min_font_size)} * ((100vw - #{$min_vw}) / #{strip-unit($max_vw - $min_vw)}));
				line-height: calc(#{$min_line_height} + #{strip-unit($max_line_height - $min_line_height)} * ((100vw - #{$min_vw}) / #{strip-unit($max_vw - $min_vw)}));
			}
			@media screen and (min-width: $max_vw) {
				font-size: $max_font_size;
				line-height: $max_line_height;
			}
		}
	}
};
  
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
};

@mixin background($path, $repeat: no-repeat, $position: center, $size: auto, $color: initial) {
    background: {
        image: url($path) !important;
		repeat: $repeat !important;
        position: $position !important;
		size: $size !important;
		color: $color !important;
    }
}
