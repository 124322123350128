.gs_card {
	position: relative;
	background-color: $pale--tertiary;
	border-radius: $radius;
	padding: 24px;

	@include max-width($tablet) {
		padding: 16px;
	}
}
